@import 'src/styles/variables';

.loginActionsContainer {
  margin-top: auto;

  @include for-size(phone-only) {
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
  }
}

.contentWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
  max-width: 1200px;
  z-index: $z-base;

  @include for-size(phone-only) {
    width: 100%;
    margin: auto;
  }
}

.introContainer {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 30px;

  @include for-size(tablet-landscape-up) {
    flex-basis: 50%;
    align-items: center;
    justify-content: start;
  }

  @include for-size(phone-only) {
    display: none;
  }
}

.introImage {
  width: 100%;
  max-width: 600px;
}

.introText {
  font-size: 36px;
  font-weight: 300;
  color: $white;
  line-height: 1.2;

  @include for-size(tablet-landscape-up) {
    font-size: 48px;
  }
}

.formContainer {
  max-width: 500px;

  @include for-size(tablet-landscape-up) {
    flex-basis: 40%;
    align-items: center;
  }
}

.logoContainer {
  width: 100%;
  display: flex;
}

.logo {
  margin: auto;
  // height: 100%;
  // width: 180px;
  padding: 20px;
}

.card {
  width: 100%;
  padding: 30px;
  margin: 40px 0;
  box-sizing: border-box;
  // min-height: 400px;
  background: $white;
  border-radius: 5px;
  box-shadow: $big-shadow;

  @include for-size(phone-only) {
    margin: 20px;
    padding: 20px;
    width: auto;
  }
}

.signup {
  color: $white;

  padding: 0 25px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.signupLink {
  color: $white;

  &:hover {
    color: $yellow-100;
  }
}

.header {
  margin-bottom: 40px;
}

.headline {
  color: $black;
  line-height: 34px;
  font-size: 26px;
  margin-bottom: 12px;

  @include for-size(phone-only) {
    font-size: 22px;
  }
}

.description {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
}

.forgotPwd {
  margin-left: 3px;
  color: $blue;

  &:hover {
    color: $darkblue;
  }
}

.confirmActionContainer {
  margin-top: auto;

  @include for-size(phone-only) {
    width: 100%;
  }
}

.confirmMessage {
  border-radius: 3px;
  padding: 10px;
  margin: 5px 0;
  margin-bottom: 20px;
  background-color: $green-10;
  // color: $green;
}

.errorMessage {
  border-radius: 3px;
  padding: 10px;
  margin: 5px 0;
  margin-bottom: 20px;
  background-color: $red-10;
  color: $darkred;
}

.loginButton {
}
