@import "src/styles/variables";

.container {
  width: 100%;
  box-sizing: border-box;
  height: 56px + 2px;
  border-radius: 4px;
  position: relative;
  background-color: $white;
  transition: 0.3s background-color $cubic, 0.3s box-shadow $cubic;
  border: 1px solid $gray-40;
  overflow: hidden;

  .field::-webkit-input-placeholder {
    color: $darkblue-alt;
  }

  .field::-moz-placeholder {
    color: $darkblue-alt;
  }

  .field:-ms-input-placeholder {
    color: $darkblue-alt;
  }

  .field:-moz-placeholder {
    color: $darkblue-alt;
  }

  &.active {
    background-color: $white;
    // box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);

    .field::-webkit-input-placeholder {
      color: $gray-40;
    }

    .field::-moz-placeholder {
      color: $gray-40;
    }

    .field:-ms-input-placeholder {
      color: $gray-40;
    }

    .field:-moz-placeholder {
      color: $gray-40;
    }


    .field {
      padding: 24px 76px 8px 16px;
    }

    .field+.label {
      top: 4px;
      opacity: 1;
      color: $darkblue;
    }

  }


  .field {
    box-sizing: border-box;
    width: 100%;
    height: 56px;
    position: relative;
    padding: 0px 16px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 300;
    line-height: normal;
    background-color: transparent;
    color: $black;
    outline: none;
    box-shadow: 0px 4px 20px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
      0.1s padding ease-in-out;
    -webkit-appearance: none;
  }

  &.active {
    .field {
      padding: 24px 76px 8px 16px;
    }
  }

  .field+.label {
    position: absolute;
    top: 24px;
    left: 16px;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    color: $white;
    opacity: 0;
    pointer-events: none;
    transition: 0.1s all ease-in-out;
  }

  .field+.label.error {
    color: $strongred;
  }

  &:hover {
    background-color: $white;
    // background-color: rgba(255, 255, 255, 0.45);
    // box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  }

  &:focus {
    background-color: $white;
    // transition: 0.3s all $cubic;
    // border-radius: 4px;
    // outline: 1px solid red;
    // outline-offset: -2px;
  }

  &.locked {
    pointer-events: none;
  }


}

.button {
  background-color: $gray-10;
  color: $gray-80;
  font-size: 12px;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 18px;
  position: absolute;
  padding: 1px 6px;
  top: calc(50% - 10px);
  right: 12.5px;
  cursor: pointer;
  z-index: 1;
  user-select: none;
}


.container {
  // border-radius: 3px;
  // box-sizing: border-box;
  // color: $black;
  // font-size: 18px;
  // line-height: 1;
  // position: relative;


}


.passwordInput {
  border: none;
  box-sizing: border-box;
  font-size: 14px;
  padding: 12px 0 8px 12px;
  width: calc(85% - 28px);

  &:not(:focus).isPasswordInvalid {
    color: #D1462F;
  }

  &:focus {
    outline: none;
  }
}

.strength {
  box-sizing: border-box;
  height: 3px;
  position: relative;
  top: 0;
  right: 0;
  transition: width .3s $cubic;
  width: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  margin: -3px 0 0;

  &.score0 {
    background: #D1462F;
    width: 20%;
  }

  &.score1 {
    background: #D1462F;
    width: 40%;
  }

  &.score2 {
    background: #57B8FF;
    width: 60%;
  }

  &.score3 {
    background: #57B8FF;
    width: 80%;
  }

  &.score4 {
    background: #2FBF71;
    width: calc(100% + 2px);
  }

}